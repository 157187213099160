import Vue from "vue";
import LocaleCurrency from "locale-currency";

const getCurrencyData = (isoCode="de")=>{
    let local = isoCode || 'de';
    let currency = {
        currency:LocaleCurrency.getCurrency(local) ||'EUR',
    };

    let formatter = new Intl.NumberFormat(local,{
        style: 'currency',
        currency: currency.currency,
    })

    formatter.formatToParts(120000.99).forEach(entry=>{
        switch (entry.type) {
            case "currency":
                currency.symbol = entry.value
                break;
            case "group":
                currency.thousandsSeparator = entry.value
                break;
            case "decimal":
                currency.fractionSeparator = entry.value
                break;
            case "fraction":
                currency.fractionCount = entry.value.length
                break;
        }
    })

    return currency;
}

export const handleCurrencyFormatting = (isoCode="de")=>{
    let geolLocal = isoCode || 'de';

    let currencyData = getCurrencyData(geolLocal);

    let formatter = new Intl.NumberFormat(geolLocal,{
        style: 'currency',
        currency: currencyData.currency,
    })

    window.store.commit('settings/setCurrencyData',currencyData);

    Vue.filter('currency',(value)=>{
        return formatter.format(value);
    });
}



export const formatCurrency = (value)=>{
    let geolLocale = window.store.getters['settings/getSettingValue']('geoloc_systemcountry') || 'de';

    let currencyFormatter = new Intl.NumberFormat(geolLocale,{
        style: 'currency',
        currency: String(LocaleCurrency.getCurrency(geolLocale)) ||'EUR',
    })
    return currencyFormatter.format(value);
}

